import React, { useState } from 'react';
import axios from 'axios';

const App = () => {
  const [messages, setMessages] = useState([
    { sender: 'bot', text: 'Hello! How can I assist you today?' }
  ]);
  const [input, setInput] = useState('');

  // Function to send messages to the API
  const aiBtc = async (userMessage) => {
    return new Promise(async (resolve, reject) => {
      try {
        const apiUrl = `https://api.botcahx.eu.org/api/search/openai-chat`; // URL API asli
        const apiKey = 'mutiadevcahx';
        const { data } = await axios.get(`${apiUrl}?text=${encodeURIComponent(userMessage)}&apikey=${apiKey}`);
        resolve(data);
      } catch (error) {
        console.error("Error fetching data from API", error);
        reject("Sorry, I couldn't process your request.");
      }
    });
  };

  const handleSend = async () => {
    if (input.trim()) {
      setMessages([...messages, { sender: 'user', text: input }]);
      const userMessage = input;
      setInput('');

      // Get the response from the API
      try {
        const botResponse = await aiBtc(userMessage);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'bot', text: botResponse || "No response from the bot." }
        ]);
      } catch (error) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'bot', text: "Sorry, I couldn't process your request." }
        ]);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  return (
    <div className="flex flex-col h-screen bg-black text-white">
      {/* Header */}
      <header className="p-4 bg-gray-900 text-center text-lg font-semibold">
        Mitdev AI Chatbot
      </header>

      {/* Chat Area */}
      <div className="flex-1 p-4 overflow-y-auto">
        {messages.map((message, index) => (
          <div key={index} className={`my-2 flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
            <div className={`p-3 rounded-xl max-w-xl ${message.sender === 'user' ? 'bg-gray-700' : 'bg-gray-800'} bg-opacity-60 backdrop-blur-md text-sm`}>
              {message.text.includes('```') ? (
                <pre className="bg-gray-800 p-2 rounded-xl overflow-auto text-green-400">
                  {message.text}
                </pre>
              ) : (
                <p>{message.text}</p>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Input Area */}
      <footer className="p-4 bg-gray-900 bg-opacity-50">
        <div className="flex items-center space-x-4">
          <input
            type="text"
            className="flex-1 p-3 rounded-lg bg-gray-700 bg-opacity-50 text-white outline-none"
            placeholder="Type your message..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button
            className="p-3 bg-gray-700 bg-opacity-50 text-white rounded-lg hover:bg-gray-600 transition-colors"
            onClick={handleSend}
          >
            Send
          </button>
        </div>
      </footer>
    </div>
  );
};

export default App;
